import { useEffect } from 'react';

export const useFavicon = (theme: any) => {
    useEffect(() => {
        const favicon: HTMLAnchorElement | null = document.getElementById('favicon') as HTMLAnchorElement;
        if (theme?.favicon && favicon) {
            favicon.href = theme.favicon;
        }
    }, [theme]);
};
