import { getStoredAnsweredIds, setStoredAnsweredIds } from '../utils/functions';
import { useEffect, useState } from 'react';

import { SurveyType } from '../utils/types';

export const useAnsweredIds = (survey: SurveyType | undefined) => {
    const [answeredIds, setAnsweredIds] = useState<number[]>([]);

    useEffect(() => {
        if (survey) {
            const idsFromStorage =
                getStoredAnsweredIds(survey.id) !== null
                    ? (JSON.parse(getStoredAnsweredIds(survey.id)!) as number[])
                    : [];
            const uniqueNewIds = answeredIds.filter((id) => {
                return !idsFromStorage.includes(id);
            });
            setStoredAnsweredIds(survey.id, [...idsFromStorage, ...uniqueNewIds]);
        }
    }, [answeredIds, survey]);

    const answeredIdsFromStorage =
        survey && getStoredAnsweredIds(survey.id)
            ? (JSON.parse(getStoredAnsweredIds(survey.id)!) as number[])
            : [];

    return {
        answeredIds,
        setAnsweredIds,
        answeredIdsFromStorage,
    };
};
