import { Button, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import Question from '../Question/Question';
import { OpenQuestion } from '../OpenQuestion/OpenQuestion';
import { QuestionType, SurveyProps } from '../../utils/types';
import { SendOutlined } from '@ant-design/icons';

interface ValuesType {
    [key: string]: string[] | string | null;
}

const Survey: React.FC<SurveyProps> = ({ survey, questions, onSubmit, wasAnswered, slotTitle }) => {
    const [values, setValues] = useState<ValuesType>({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        let tempValues: ValuesType = {};
        questions?.forEach((item: any) => {
            tempValues[item.id] = null;
        });
        setValues(tempValues);
    }, [survey, questions]);

    useEffect(() => {
        let disabledSubmit = false;

        Object.entries(values).forEach(([key, value]) => {
            const question = questions?.find((question) => question.id === key);
            const questionMin =
                typeof question?.min_number_of_answers === 'number' ? question?.min_number_of_answers : 1;

            if (question?.is_open) {
                if (questionMin > 0 && !value) disabledSubmit = true;
            } else {
                const isQuestionRadioButton = question?.is_radio || false;
                const isValueAnArray = Array.isArray(value);
                if (
                    questionMin > 0 &&
                    (!value ||
                        (isValueAnArray &&
                            (value.length === 0 || (value.length < questionMin && !isQuestionRadioButton))))
                ) {
                    disabledSubmit = true;
                }
            }
        });
        setIsButtonDisabled(disabledSubmit);
    }, [values, questions]);

    const onChange = (fieldName: string, value: string[] | string) => {
        setValues((values) => {
            return {
                ...values,
                [fieldName]: value,
            };
        });
    };

    if (wasAnswered) {
        return (
            <div className="Survey-answered-notice">
                {survey?.answered_feedback_message || 'Thank you for your feedback'}
            </div>
        );
    }
    return (
        <>
            {slotTitle && !!survey?.is_multiple_slot && (
                <div className="Survey-single-slot-title">{slotTitle}</div>
            )}
            <Form>
                {questions?.map((item: QuestionType) => {
                    if (!!item.is_open) {
                        return (
                            <OpenQuestion
                                key={`question-row-${item.id}`}
                                question={item}
                                onChange={(e) => onChange(item.id, e)}
                                shouldRenderAsterisk={
                                    item?.min_number_of_answers > 0 && !!survey?.required_message
                                }
                            />
                        );
                    } else {
                        return (
                            <Question
                                question={item}
                                key={`question-row-${item.id}`}
                                onChange={(e: string[]) => onChange(item.id, e)}
                                surveyStyles={survey?.styles}
                                shouldRenderAsterisk={
                                    item?.min_number_of_answers > 0 && !!survey?.required_message
                                }
                            />
                        );
                    }
                })}
                {survey?.required_message && (
                    <div className={'Survey-required-message-helper mx-3 my-4'}>
                        {survey?.required_message}
                    </div>
                )}
                {survey?.styles?.submitButtonPositionFixed !== 'true' ? (
                    <Button
                        variant="primary"
                        onClick={() => onSubmit(values)}
                        disabled={isButtonDisabled}
                        className="Madai-submit-button"
                    >
                        {survey?.submit_message || 'Vote'}
                        <span className={'Madai-submit-button__icon'}>
                            <SendOutlined />
                        </span>
                    </Button>
                ) : (
                    <div className="Madai-submit-button-fixed-wrapper">
                        <Button
                            variant="primary"
                            onClick={() => onSubmit(values)}
                            disabled={isButtonDisabled}
                            className="Madai-submit-button"
                        >
                            {survey?.submit_message || 'Vote'}
                            <span className={'Madai-submit-button__icon'}>
                                <SendOutlined />
                            </span>
                        </Button>
                    </div>
                )}
            </Form>
        </>
    );
};

export default Survey;
