const getSignatreVarKey = (surveyId: string): string => {
    return `${process.env.REACT_APP_SIGNATURE_PREFIX}-${surveyId}`;
};

const getStoredAnsweredIdsVarKey = (surveyId: string): string => {
    return `${process.env.REACT_APP_STORED_ANSWERED_IDS_PREFIX}-${surveyId}`;
};

export const setStoredAnsweredIds = (surveyId: string, ids: number[]) => {
    localStorage.setItem(getStoredAnsweredIdsVarKey(surveyId), JSON.stringify(ids));
};

export const getStoredAnsweredIds = (surveyId: string): string | null => {
    return localStorage.getItem(getStoredAnsweredIdsVarKey(surveyId));
};

export const setSignature = (surveyId: string, signature: string) => {
    localStorage.setItem(getSignatreVarKey(surveyId), signature);
};

export const getSignature = (surveyId: string): string | null => {
    return localStorage.getItem(getSignatreVarKey(surveyId));
};

export const getMaxQuestionsReloadDelay = (): number => {
    return process.env.REACT_APP_MAX_QUESTIONS_RELOAD_DELAY
        ? parseInt(process.env.REACT_APP_MAX_QUESTIONS_RELOAD_DELAY)
        : 3000;
};

export const getQuestionsReloadDelay = (): number => {
    return Math.floor(Math.random() * getMaxQuestionsReloadDelay());
};
