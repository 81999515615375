import React from 'react';
import "./Notice.scss"

const Notice: React.FC<any> = ({ children }) => {

    return (
        <div className='notice'>
            <div className='notice__content' dangerouslySetInnerHTML={{__html: children}}/>
        </div>
    );
}

export default Notice;
