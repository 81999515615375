import client from './client';

export const getSurveyData = async ({ domain, slug, skipLog = false }: any) => {
    let params: any = { domain, slug };

    if (skipLog) {
        params = {
            ...params,
            skipLog,
        };
    }
    params = new URLSearchParams(params);
    const query = params.toString();
    return await client.get(`survey?${query}`);
};

export const getSurveySlots = async (surveyId: string) => {
    return await client.get(`surveys/${surveyId}/current-slots`);
};

export const submitData = async (surveyId: string, data: any) => {
    return await client.post(`surveys/${surveyId}/answer`, data);
};
