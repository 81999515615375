import React, { ChangeEvent, useState } from 'react';
import { QuestionProps } from '../../utils/types';
import Option from '../Option/Option';
import './Question.scss';
import classNames from 'classnames';
import { QuestionSlider } from './QuestionSlider/QuestionSlider';

const Question: React.FC<QuestionProps> = ({ question, onChange, surveyStyles, shouldRenderAsterisk }) => {
    const [value, setValue] = useState<string[]>([]);
    const [isMaxReached, setIsMaxReached] = useState<boolean>(false);

    const onChangeVlaue = (e: ChangeEvent<HTMLInputElement>) => {
        let newValue = [...value];

        if (question.is_radio) {
            newValue = [];
            newValue.push(e.target.id);
        } else {
            const indexOf = value.indexOf(e.target.id);
            if (indexOf === -1) {
                newValue.push(e.target.id);
            } else {
                newValue.splice(indexOf, 1);
            }
        }

        setValue(newValue);
        onChange(checkValue(newValue) ? newValue : []);
    };

    const onChangeValueSlider = (valueSliderNumber: number) => {
        const value = question.options?.[valueSliderNumber].id;

        setValue([value]); // setting value just for consistency reason, not actually needed for any processing (yet)
        onChange([value]);
    };

    const checkValue = (newValue: string[]) => {
        const numberOfAnswers = newValue.length;

        const _isMax = !!(
            question.max_number_of_answers && numberOfAnswers === question.max_number_of_answers
        );
        const _isOverMax = question.max_number_of_answers && numberOfAnswers > question.max_number_of_answers;
        setIsMaxReached(_isMax);
        if (!_isOverMax) {
            return true;
        }
        return false;
    };

    const isOptionCircle = surveyStyles?.questionOptionRadioAsCircle === 'true';

    const questionOptionsClassName = classNames('Question-options mt-3 pt-3', {
        'text-center': question.is_icon,
        'with-circle-options': isOptionCircle,
    });

    return (
        <div className="Question">
            <div className="Question-card p-3 m-3">
                {question?.title && (
                    <div className="Question-title">
                        {question.title}
                        {shouldRenderAsterisk && <span> *</span>}
                    </div>
                )}
                {question?.subtitle && <div className="Question-subtitle">{question.subtitle}</div>}
                {question?.description && <div className="Question-description">{question.description}</div>}
                <div className={questionOptionsClassName}>
                    {question.question_type === 0 &&
                        question?.options?.map((item: any) => {
                            return (
                                <Option
                                    option={item}
                                    isIcon={!!question?.is_icon}
                                    key={item.id}
                                    onChange={onChangeVlaue}
                                    disabled={!question.is_radio && isMaxReached && !value.includes(item.id)}
                                    isRadio={!!question?.is_radio}
                                    pale={value?.length > 0 && !value.includes(item.id)}
                                    selected={value.includes(item.id)}
                                    isCircle={isOptionCircle}
                                />
                            );
                        })}
                    {question.question_type === 1 && (
                        <QuestionSlider question={question} onChange={onChangeValueSlider} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Question;
