import './../App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Badge, Form, Select, Tabs } from 'antd';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { OPEN, POST, PRE, transizionClasNamePrefix, transizionTimeout } from '../utils/constants';
import { SlotType, SurveyType } from '../utils/types';
import { getQuestionsReloadDelay, getSignature, setSignature } from '../utils/functions';
import { getSurveyData, getSurveySlots, submitData } from '../utils/api';
import { useEffect, useState } from 'react';

import { CSSTransition } from 'react-transition-group';
import Cookies from 'js-cookie';
import GlobalStyle from '../styles/globalStyle';
import Notice from '../ui/Notice/Notice';
import Pusher from 'pusher-js';
import Survey from '../components/Survey/Survey';
import SurveyWrapper from '../components/SurveyWrapper/SurveyWrapper';
import { ThemeProvider } from 'styled-components';
import { useAnsweredIds } from '../hocs/useAnsweredIds';
import { useFavicon } from '../hocs/useFavicon';
import { useParams } from 'react-router-dom';

function MainPage() {
    const [survey, setSurvey] = useState<SurveyType | undefined>();
    const [surveySlots, setSurveySlots] = useState<SlotType[]>([]);

    const [loading, setLoading] = useState<boolean>(true);
    const [surveyStatus, setSurveyStatus] = useState<number | undefined>(0);
    const [theme, setTheme] = useState<any>({ theme: null });
    const [isAnswering, setIsAnswering] = useState<boolean>(false);
    const [errors, setErrors] = useState([]);

    const [errorModal, setErrorModal] = useState<string | undefined>();
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [showBody, setShowBody] = useState<boolean>(false);

    const { surveySlug } = useParams();
    const { answeredIds, setAnsweredIds, answeredIdsFromStorage } = useAnsweredIds(survey);

    useFavicon(theme);

    var _paq = (window._paq = window._paq || []);

    useEffect(() => {
        loadSurveyData();
    }, []);

    useEffect(() => {
        if (surveyStatus === OPEN) {
            _paq.push(['trackEvent', 'WILL', 'Active Survey', 'OPEN']);
        }
    }, [surveyStatus]);

    useEffect(() => {
        if (survey) {
            /** start analytics */
            const analytics_id = survey?.analytics_id;
            if (analytics_id) {
                _paq.push(['trackPageView']);
                _paq.push(['enableLinkTracking']);
                console.log('trackPageView');
                (function () {
                    var u = process.env.REACT_APP_MATOMO_URL;
                    _paq.push(['setTrackerUrl', u + 'matomo.php']);
                    _paq.push(['setSiteId', analytics_id]);
                    console.log('setSiteId');
                    var d = document,
                        g = d.createElement('script'),
                        s = d.getElementsByTagName('script')[0];
                    g.async = true;
                    g.src = u + 'matomo.js';
                    s?.parentNode?.insertBefore(g, s);
                })();
            }
            /** finish analytics */

            setSurveyStatus(survey.status);
            const surveySlotsWithAtLeastOneQuestion = survey.survey_slots.filter((surveySlot) => {
                return surveySlot.questions?.length > 0;
            });
            setSurveySlots(surveySlotsWithAtLeastOneQuestion);
            setTheme({
                ...survey.styles,
                ...survey.media,
            });
            document.title = survey.title;
            initPusher(survey.id);
        }
    }, [survey]);

    const initPusher = (surveyId: string) => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', {
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER || '',
        });
        var channel = pusher.subscribe(`will-channel-${surveyId}`);

        channel.bind('App\\Events\\SurveyStatusChanged', (data: any) => {
            setSurveyStatus(data?.status);
        });
        channel.bind('App\\Events\\SingleSurveySlotChanged', () => {
            loadSurveySlots(surveyId, true);
        });
        channel.bind('App\\Events\\SurveySlotPublished', () => {
            loadSurveySlots(surveyId);
        });
        channel.bind('App\\Events\\SurveySlotFinished', () => {
            loadSurveySlots(surveyId);
        });
    };

    const loadSurveySlots = async (surveyId: string, resetAnsweredIds?: boolean) => {
        setLoading(true);
        setTimeout(async () => {
            try {
                const res = await getSurveySlots(surveyId);
                const surveySlotsWithAtLeastOneQuestion =
                    res.data?.data?.filter((surveySlot: SlotType) => {
                        return surveySlot.questions?.length > 0;
                    }) || [];
                setSurveySlots(surveySlotsWithAtLeastOneQuestion);
            } catch (e) {
                handleShowErrorModal(
                    'There was an error while loading the survey. Please try reloading the page.'
                );
            } finally {
                setLoading(false);
                if (resetAnsweredIds === true) {
                    setAnsweredIds([]);
                }
            }
        }, getQuestionsReloadDelay());
    };

    const loadSurveyData = async () => {
        setErrors([]);
        setLoading(true);
        try {
            const cookieName = process.env.REACT_APP_VISITOR_COOKIE_NAME || '';
            let surveyDetectOptions: any = {
                domain: window.location.hostname,
                slug: surveySlug || '',
            };
            if (Cookies.get(cookieName)) {
                surveyDetectOptions = {
                    ...surveyDetectOptions,
                    skipLog: true,
                };
            } else {
                Cookies.set(cookieName, Date.now().toString());
            }
            const res = await getSurveyData(surveyDetectOptions);
            setSurvey(res.data.data);
        } catch (e) {
            handleShowErrorModal(
                'There was an error while loading the survey. Please try reloading the page.'
            );
        } finally {
            setLoading(false);
        }
    };

    const sendAnswers = (slotId: number) => async (e: any) => {
        setIsAnswering(true);
        if (survey?.id) {
            try {
                const res = await submitData(survey.id, {
                    answers: e,
                    survey_slot_id: slotId,
                    signature: getSignature(survey.id),
                });
                if (!getSignature(survey.id)) {
                    setSignature(survey.id, res.data.signature);
                }
                setAnsweredIds((ps: number[]) => [...ps, slotId]);
                window?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } catch (e: any) {
                handleShowErrorModal(e.response?.data?.message);
            } finally {
                setIsAnswering(false);
            }
        }
    };

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
        setErrorModal('');
    };

    const handleShowErrorModal = (error?: string) => {
        setErrorModal(error);
        setShowErrorModal(true);
    };

    const handleShowBody = (value: boolean) => {
        setShowBody(value);
    };

    let surveyContent = null;

    const tabBarStyles = {
        marginTop: 20,
        display: 'none',
    };

    const [selectedSlot, setSelectedSlot] = useState<number | null>(null);
    useEffect(() => {
        if (selectedSlot === null && surveySlots.length) {
            setSelectedSlot(surveySlots[0].id);
        } else if (surveySlots.length && surveySlots.find((slot) => slot.id === selectedSlot) === undefined) {
            setSelectedSlot(surveySlots[0].id);
        }
    }, [surveySlots, selectedSlot]);
    const tabsActiveKey = surveySlots.find((slot) => slot.id === selectedSlot)?.id;
    const thereIsUnansweredSlot =
        surveySlots.filter((slot) => {
            return answeredIdsFromStorage?.includes(slot.id);
        }).length === surveySlots.length;
    const notificationNumber =
        surveySlots?.length -
        surveySlots?.filter((slot) => {
            return answeredIdsFromStorage?.includes(slot.id);
        })?.length;
    const displaySlotSelect = surveySlots?.length === 1 || surveySlots?.length === 0;

    switch (surveyStatus) {
        case OPEN:
            surveyContent = (
                <>
                    {errors?.length ? (
                        <div>Errors</div>
                    ) : (
                        <SurveyWrapper
                            survey={survey}
                            displayLegendDescription={
                                tabsActiveKey !== undefined && !answeredIds?.includes(tabsActiveKey)
                            }
                        >
                            <Form
                                layout="vertical"
                                style={{
                                    padding: '0 1rem',
                                    display: displaySlotSelect ? 'none' : 'block',
                                }}
                                aria-hidden={displaySlotSelect ? 'true' : 'false'}
                            >
                                <Form.Item
                                    label={
                                        <Badge
                                            offset={[15, 7]}
                                            count={
                                                thereIsUnansweredSlot
                                                    ? 0
                                                    : notificationNumber > 0
                                                    ? notificationNumber
                                                    : 1
                                            }
                                        >
                                            Select a question group
                                        </Badge>
                                    }
                                    className="App-slot-select"
                                >
                                    <Select
                                        value={selectedSlot}
                                        style={{ width: '100%', maxWidth: 425 }}
                                        onChange={(value) => setSelectedSlot(value)}
                                        options={surveySlots.map((slot) => {
                                            return {
                                                value: slot.id,
                                                label: (
                                                    <Badge
                                                        offset={[6, 3]}
                                                        dot={!!!answeredIdsFromStorage?.includes(slot.id)}
                                                    >
                                                        {slot.title}
                                                    </Badge>
                                                ),
                                            };
                                        })}
                                    ></Select>
                                </Form.Item>
                            </Form>
                            <Tabs
                                activeKey={tabsActiveKey !== undefined ? String(tabsActiveKey) : undefined}
                                tabBarStyle={tabBarStyles}
                                items={surveySlots.map((slot) => {
                                    return {
                                        key: String(slot.id),
                                        label: slot.title,
                                        forceRender: true,
                                        children:
                                            tabsActiveKey === slot.id ? (
                                                <Survey
                                                    slotTitle={
                                                        surveySlots?.length === 1 ? slot.title : undefined
                                                    }
                                                    survey={survey}
                                                    questions={slot.questions}
                                                    onSubmit={sendAnswers(slot.id)}
                                                    wasAnswered={answeredIds?.includes(slot.id) || false}
                                                />
                                            ) : null,
                                    };
                                })}
                            />
                        </SurveyWrapper>
                    )}
                </>
            );
            break;
        case PRE:
            surveyContent = <Notice>{survey?.pre_message}</Notice>;
            break;
        case POST:
            surveyContent = <Notice>{survey?.post_message}</Notice>;
            break;
        default:
            surveyContent = <Notice>{survey?.closed_message}</Notice>;
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <div className="App">
                    <div className="App-header">
                        <CSSTransition
                            in={loading || isAnswering}
                            timeout={transizionTimeout}
                            classNames={transizionClasNamePrefix}
                            unmountOnExit
                            onEntering={() => handleShowBody(false)}
                            onExited={() => handleShowBody(true)}
                        >
                            <div className="App-spinner-container">
                                <Spinner animation="border" variant="light" />
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            in={showBody}
                            timeout={transizionTimeout}
                            classNames={transizionClasNamePrefix}
                            unmountOnExit
                        >
                            {surveyContent}
                        </CSSTransition>
                    </div>
                    <Modal
                        show={showErrorModal}
                        onHide={handleCloseErrorModal}
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title>Warning</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{errorModal}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseErrorModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </ThemeProvider>
        </>
    );
}

export default MainPage;
