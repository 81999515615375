import axios from "axios";

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
    headers: {
        "X-Requested-With": "XMLHttpRequest"
    }
});

client.defaults.headers.common['Accept'] = 'application/json';
client.defaults.headers.common['Content-Type'] = 'application/json';
client.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if ([401, 419].includes(error.response.status)) {
            console.log("Error 4xx Unauthenticated")
        }
        return Promise.reject(error);
    }
);
export default client;
