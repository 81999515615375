import { QuestionType } from '../../../utils/types';
import React, { useState } from 'react';
import { Slider, Space } from 'antd';

import './QuestionSlider.scss';

interface QuestionSliderProps {
    question: QuestionType;
    onChange: (value: number) => void;
}

export const QuestionSlider: React.FC<QuestionSliderProps> = ({ question, onChange }) => {
    const range = question.options.length - 1;

    const [value, setValue] = useState<number>(0);

    const tooltipFormatter = (value: number | undefined): React.ReactNode => {
        if (value === undefined) return null;
        return (
            <Space direction="vertical" align="center">
                {question.options[value]?.title}
                {question.options[value]?.media && <div className="question-slider-element__image" />}
            </Space>
        );
    };

    return (
        <>
            <style>
                {`
                    .question-slider-element__tooltip--${question.id} .ant-tooltip-arrow {
                        height: 40px;
                        width: 40px;
                        top: -10px;
                        background-color: ${question.options[value]?.text_color};
                        
                        ${
                            question.options[value]?.media?.endsWith('.svg')
                                ? `-webkit-mask: url(${question.options[value]?.media!}) no-repeat center; 
                        mask: url(${question.options[value]?.media!}) no-repeat center;`
                                : `background: url("${question.options[value]?.media!}");
                        background-size: contain!important;
                        background-repeat: no-repeat;
                        background-position: center;`
                        } 
                    }
                    .question-slider-element--${question.id} {
                        margin-top: ${!!question.options[value]?.media ? '65px' : '35px'}
                    }
                    
                    .question-slider-element--${question.id} .ant-slider-handle::after {
                        background-color: ${question.options[value]?.text_color}!important;
                        box-shadow: 0 0 0 4px ${question.options[value]?.text_color}!important;
                    }
                    .question-slider-element__tooltip--${question.id} .ant-tooltip-inner {
                        color: ${question.options[value]?.text_color}!important;
                    }
                `}
            </style>
            <Slider
                min={0}
                defaultValue={value}
                max={range}
                tooltip={{
                    open: true,
                    formatter: tooltipFormatter,
                    rootClassName: `question-slider-element__tooltip question-slider-element__tooltip--${question.id}`,
                    getTooltipContainer: (element) => element
                }}
                onChange={(number) => {
                    setValue(number);
                    onChange(number);
                }}
                className={`question-slider-element question-slider-element--${question.id}`}
            />
        </>
    );
};
