import './SurveyWrapper.scss';

import { SurveyType } from '../../utils/types';
import { ThemeContext } from 'styled-components';
import { useContext } from 'react';
import { Col, Row, Container } from 'react-bootstrap';

const SurveyWrapper: React.FC<{
    children: React.ReactNode;
    survey: SurveyType | undefined;
    displayLegendDescription: boolean;
}> = ({ survey, children, displayLegendDescription }) => {
    const themeContext = useContext(ThemeContext);

    return (
        <div className="Survey">
            {themeContext.logoTop && (
                <div className="Survey-logo Survey-logo__top py-3">
                    <Container>
                        <Row>
                            <Col className="col-12 col-xl-8 offset-xl-2">
                                <img src={themeContext.logoTop} alt="Logo" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
            <Container>
                <Row>
                    <Col className="col-12 col-xl-8 offset-xl-2">
                        <div className="Survey-card Survey-card--titles">
                            {survey?.title && <div className="Survey-title">{survey.title}</div>}
                            {survey?.subtitle && <div className="Survey-subtitle">{survey.subtitle}</div>}
                            {displayLegendDescription && survey?.description && (
                                <div
                                    className="Survey-description-legend"
                                    dangerouslySetInnerHTML={{ __html: survey?.description }}
                                />
                            )}
                        </div>
                        {children}
                        {themeContext.logoBottom && (
                            <div className="Survey-logo Survey-logo__bottom">
                                <img src={themeContext.logoBottom} alt="Logo" />
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SurveyWrapper;
