import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import ErrorPage from './pages/ErrorPage';
import MainPage from './pages/MainPage';
// import Page404 from './pages/404Page';
import { useEffect } from 'react';

function App() {
    useEffect(() => {
        for (let i = 0; i <= 10; i++) {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.href = `/img/icons/${i}.jpg`;
            link.as = 'image';
            document.head.appendChild(link);
        }
    }, []);

    const router = createBrowserRouter([
        {
            path: '/',
            element: <MainPage />,
            errorElement: <ErrorPage />,
        },
        {
            path: '/:surveySlug',
            element: <MainPage />,
            errorElement: <ErrorPage />,
        },
    ]);
    return <RouterProvider router={router} />;
}

export default App;
