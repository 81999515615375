import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
${({ theme }: any) => {
    const surveyLogoWidthDesktop = theme.surveyLogoWidthDesktop || '50%';
    const surveyLogoWidthMobile = theme.surveyLogoWidthMobile || surveyLogoWidthDesktop;

    const surveyTitleFontSizeDesktop = theme.surveyTitleFontSizeDesktop || 'calc(1rem + 3vmin)';
    const surveyTitleFontSizeMobile = theme.surveyTitleFontSizeMobile || surveyTitleFontSizeDesktop;

    const surveySubtitleFontSizeDesktop = theme.surveySubtitleFontSizeDesktop || 'calc(1rem + 2vmin)';
    const surveySubtitleFontSizeMobile = theme.surveySubtitleFontSizeMobile || surveySubtitleFontSizeDesktop;

    const questionTitleFontSizeDesktop = theme.questionTitleFontSizeDesktop || 'calc(1rem + 2vmin)';
    const questionTitleFontSizeMobile = theme.questionTitleFontSizeMobile || questionTitleFontSizeDesktop;
    const optionTitleFontSizeDesktop = theme.optionTitleFontSizeDesktop || 'calc(1rem + 1vmin)';
    const optionTitleFontSizeMobile = theme.optionTitleFontSizeMobile || optionTitleFontSizeDesktop;

    return theme
        ? `
    ${
        theme.bodyFont
            ? `@import url('https://fonts.googleapis.com/css?family=${theme.bodyFont}&display=swap');`
            : null
    }
      :root {
        --logo-container-background-color: ${
            theme.logoContainerBackgroundColor || theme.wrapperBgColor || 'transparent'
        };

      --primary-color: ${theme.primaryColor || '#282c34'};
      --secondary-color: ${theme.secondaryColor || '#282c34'};
      --wrapper-bg-color: ${theme.wrapperBgColor || 'transparent'};

      --survey-logo-width-desktop: ${surveyLogoWidthDesktop};
      --survey-logo-width-mobile: ${surveyLogoWidthMobile};

      --survey-title-font-size-desktop: ${surveyTitleFontSizeDesktop};
      --survey-title-font-size-mobile: ${surveyTitleFontSizeMobile};
      --survey-title-text-color: ${theme.surveyTitleTextColor || theme.primaryColor};
      --survey-title-display: ${theme.surveyTitleDisplay || 'block'};

      --survey-subtitle-font-size-desktop: ${surveySubtitleFontSizeDesktop};
      --survey-subtitle-font-size-mobile: ${surveySubtitleFontSizeMobile};
      --survey-subtitle-text-color: ${
          theme.surveySubtitleTextColor || theme.surveyTitleTextColor || theme.primaryColor
      };
      --survey-subtitle-font-weight: ${theme.surveySubtitleFontWeight || 'normal'};
      --survey-subtitle-font-style: ${theme.surveySubtitleFontStyle || 'normal'};

      --survey-titles-margin-bottom: ${theme.surveyTitlesMarginBottom || '1.5rem'};
      --survey-titles-margin-top: ${theme.surveyTitlesMarginTop || '1rem'};
      
      --survey-bg-color: ${theme.surveyBgColor || 'transparent'};
      --survey-padding-bottom: ${theme.surveyPaddingBottom || '30px'};
     

      --question-title-font-size-desktop: ${questionTitleFontSizeDesktop};
      --question-title-font-size-mobile: ${questionTitleFontSizeMobile};
      --question-title-line-height-desktop: calc(${questionTitleFontSizeDesktop} * 1.6); // AUTO
      --question-title-line-height-mobile: calc(${questionTitleFontSizeMobile} * 1.6); // AUTO
      --question-title-text-color: ${theme.questionTitleTextColor || '#ffffff'};
      --question-title-font-weight: ${theme.questionTitleFontWeight || 'bold'};

      --question-subtitle-text-color: ${theme.questionSubtitleTextColor || '#ffffff'};
      --question-subtitle-font-size-mobile: ${theme.questionSubtitleFontSizeMobile || 'calc(1rem + 1vmin)'};
      --question-subtitle-font-size-desktop: ${theme.questionSubtitleFontSizeDesktop || 'calc(1rem + 1vmin)'};

      --question-bg-color: ${theme.questionBgColor || 'transparent'};

      --question-card-horizontal-padding: ${theme.questionCardHorizontalPadding || '1rem'};
      --question-card-vertical-padding: ${theme.questionCardVerticalPadding || '1rem'};
      
      --question-description-text-color: ${theme.questionDescriptionTextColor || '#ffffff'};
      --question-options-bordertop-color: ${theme.questionOptionsBordertopColor || theme.containerBgColor};
      --question-border-color: ${theme.questionBorderColor || theme.questionBgColor};
      --question-border-width: ${theme.questionBorderWidth || '1px'};
      
      --option-title-text-color: ${theme.optionTitleTextColor || '#ffffff'};
      --option-title-text-color-selected: ${
          theme.optionTitleTextColorSelected || 'var(--option-title-text-color)'
      };
      --option-title-background-color: ${theme.optionTitleBackgroundColor || 'transparent'};
      --option-title-background-color-selected: ${theme.optionTitleBackgroundColorSelected || 'transparent'};

      --option-subtitle-text-color: ${theme.optionSubtitleTextColor || '#ffffff'};
      --option-description-text-color: ${theme.optionDescriptionTextColor || '#ffffff'};
      --option-margin-bottom: ${theme.optionMarginBottom || '.5rem'};
      
      --option-title-font-size-desktop: ${optionTitleFontSizeDesktop};
      --option-title-font-size-mobile: ${optionTitleFontSizeMobile};
      --option-title-lie-height-desktop: calc(${optionTitleFontSizeDesktop} * 1.6); // AUTO
      --option-title-lie-height-mobile: calc(${optionTitleFontSizeMobile} * 1.6); // AUTO

      --submit-button-text-color: ${theme.submitButtonTextColor || '#ffffff'};
      --submit-button-bg-color: ${theme.submitButtonBgColor || theme.primaryColor || '#ffffff'};
      --submit-button-border-color: ${theme.submitButtonBorderColor || theme.primaryColor || '#ffffff'};
      --submit-button-border-width: ${theme.submitButtonBorderWidth || '1px'};
      --submit-button-padding-vertical: ${theme.submitButtonPaddingVertical || '6px'};
      --submit-button-padding-horizontal: ${theme.submitButtonPaddingHorizontal || '12px'};
      --submit-button-border-radius: ${theme.submitButtonBorderRadius || '3px'};
      --submit-button-icon-display: ${theme.submitButtonIconDisplay || 'none'};
      
      ${
          theme.bodyFont
              ? `--bs-body-font-family: ${theme.bodyFont || 'Open-Sans, Helvetica, Sans-Serif'};`
              : null
      }
    }
    body {
      margin: 0;
      padding: 0;
    }
    .App {
      &-header {
        background-repeat: no-repeat;
        background-position: ${theme.bodyBgImagePosition};
        background-size: ${theme.bodyBgImageSize || '50%'};
        ${theme.bodyBgImage ? `background-image: url(${theme.bodyBgImage});` : null}
        ${
            theme.bodyBgImageMobile
                ? `
          @media all and (max-width: 564px) {
            background-image: url(${theme.bodyBgImageMobile});
          }`
                : null
        }

      }
    }
  `
        : null;
}}
`;
export default GlobalStyle;
