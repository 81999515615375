import React from 'react';
import { Form } from 'react-bootstrap';
import { OptionProps } from '../../utils/types';
import './Option.scss';
import classNames from 'classnames';

const Option: React.FC<OptionProps> = ({
    option,
    disabled,
    onChange,
    isIcon,
    selected,
    pale,
    isRadio,
    isCircle,
}) => {
    const className = classNames('Question-options__option', {
        isIcon: isIcon,
        ['isIcon-' + parseInt(option.title)]: isIcon,
        selected: selected,
        pale: pale,
        'is-circle': isCircle,
    });

    return (
        <Form.Check
            type={isRadio ? 'radio' : 'checkbox'}
            id={option.id}
            name={option.question_id}
            label={isIcon ? '' : option.title}
            disabled={disabled}
            onChange={onChange}
            className={className}
        />
    );
};

export default Option;
