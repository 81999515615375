import React, { SyntheticEvent } from 'react';
import { OpenQuestionProps } from '../../utils/types';
import { Input } from 'antd';

import '../Question/Question.scss';

const { TextArea } = Input;

export const OpenQuestion: React.FC<OpenQuestionProps> = ({ question, onChange, shouldRenderAsterisk }) => {
    return (
        <div className="Question">
            <div className="Question-card p-3 m-3">
                {question?.title && (
                    <div className="Question-title">
                        {question.title}
                        {shouldRenderAsterisk && <span> *</span>}
                    </div>
                )}
                {question?.subtitle && <div className="Question-subtitle">{question.subtitle}</div>}
                {question?.description && <div className="Question-description">{question.description}</div>}
                <div className="mt-2 pt-3">
                    <TextArea
                        rows={4}
                        onChange={(e: SyntheticEvent) => {
                            onChange((e?.currentTarget as HTMLTextAreaElement)?.value || '');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
